import React from 'react';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import MailIcon from '@material-ui/icons/Mail';
import '../styles/Footer.css'; 

export default function Footer() {
  return (
    <div className='footer'>
        <div className='socialMedia'>
           <a href='https://www.linkedin.com/in/evangelia-moira-/'target='blank' rel='noopener noreferrer'><LinkedInIcon /></a> 
           <a href='https://github.com/EvangeliaMoira'target='blank' rel='noopener noreferrer'>  <GitHubIcon /> </a>
           <a href='mailto:euamoira@hotmail.com?Subject=Some%20subject' target='blank' rel='noopener noreferrer'> <MailIcon /> </a>
        </div>
        <p class='copyrights'>&copy; 2023 <a href='https://evangelia-moira.com'>evangelia-moira.com</a></p>
    </div>
  );
}
