import React from 'react';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import MailIcon from '@material-ui/icons/Mail';
import "../styles/Home.css";

function Home() {
  return (
    <div className='home'>
        <div className='about'>
            <image></image>
            <h2>Hello, my name is <br/> Evangelia Moira</h2>
            <div className='prompt'>
                <p>I am a software developer with passion for learning and creating. I </p>
                <a href='https://www.linkedin.com/in/evangelia-moira-/'target='blank' rel='noopener noreferrer'><LinkedInIcon /></a> 
           <a href='https://github.com/EvangeliaMoira'target='blank' rel='noopener noreferrer'>  <GitHubIcon /> </a>
           <a href='mailto:euamoira@hotmail.com?Subject=Some%20subject' target='blank' rel='noopener noreferrer'> <MailIcon /> </a>
        </div>
    </div>
       <div className="skills">
       <h1> Skills</h1>
       <ol className="list">
         <li className="item">
           <h2> Front-End</h2>
           <span>
             ReactJS, HTML, CSS, React Native, NPM, BootStrap, Yarn, TailwindCSS, StyledComponents
           </span>
         </li>
         <li className="item">
           <h2>Back-End</h2>
           <span>
             MySQL, DynamoDB, DigitalOcean, AWS S3, MS SQL, PHP
           </span>
         </li>
         <li className="item">
           <h2>Languages</h2>
           <span>JavaScript, Java, C, C++</span>
         </li>
         <li className="item">
           <h2>Android App Development</h2>
           <span>Kotlin, Java </span>
         </li>
       </ol>
     </div>
    </div>
  );
}

export default Home;