import React from 'react';
import "../styles/Resume.css";

export default function Resume() {
  const cvFileName = '../cv/Evangelia Moira.pdf'; 

  return (
    <div>
      <h1>Download My CV</h1>
      <a href={cvFileName} download>
  <button className='butt-resume'>
    <div className='back'></div>
    <div className='front'>Click</div>
    </button>
  </a>
  <h2>Thank you for reaching out!</h2>
    </div>
  );
}